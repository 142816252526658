<template>
  <div>
    <!-- {{ defaultFilters }} -->
    <InventoryTable
      :title="tLabel('Pre-Advice')"
      :headers="headers"
      :defaultFilters="defaultFilters"
      :defaultSort="defaultSort"
      :excludeButtons="{ unitNumber: true, sysTypes: true, tipoUnit: true, voyages: true }"
    >
      <template v-slot:filterButtons="{ filters }">
        <v-row align="center">
        <v-col cols="3">
          <v-text-field
            :label="tLabel('Unit number / codice merce / targa')"
            v-model="filters['B.object_codeLike']"
            @input="(val) => (filters['B.object_codeLike'] = val ? val.toUpperCase() : null)"
            clearable
          />
        </v-col>
        <v-col cols="2">
          <v-autocomplete clearable :label="tLabel('Tipo Unit')" v-model="filters.type_code" :items="unitTypes" item-text="text" item-value="value"> </v-autocomplete>
        </v-col>
        <v-col cols="2">
          <v-text-field :label="tLabel('Voyage In/Out')" v-model="filters.voyagesLike" @input="(val) => (filters.voyagesLike = val ? val.toUpperCase() : null)" clearable />
        </v-col>
        <!-- <v-col cols="2" class="text-uppercase text-center">
          <v-switch @click="toggleCarrierAlso(filters)" v-model="carrierAlso" :title="tLabel('Truck preavvisati')">
            <template v-slot:label>
              {{ tLabel("Visualizza truck") }} <v-icon class="ml-2">mdi-truck-outline</v-icon> 
            </template>
          </v-switch>
        </v-col> -->
      </v-row>
      </template>

      <template v-slot:item-menu="{ item }">
        <v-list>
          <v-list-item @click="prepareGateIn(item)" :disabled="item.canGateInTruck === false">
            <v-list-item-title class="text-uppercase">{{ tLabel("Gate-in") }}</v-list-item-title>
            <v-list-item-action>
              <v-icon color="success">mdi-import</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-list-item @click="prepareRemoveItem(item)" :disabled="item.canGateInTruck === false">
            <v-list-item-title class="text-uppercase">Elimina</v-list-item-title>
            <v-list-item-action>
              <v-icon color="error">mdi-delete</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </template>

      <template v-slot:extraButtons>
        <v-btn icon :title="tLabel('Pre-Advice/Entrance Create')" @click="goToCreatePreadvice">
          <v-icon color="primary" medium>mdi-plus</v-icon>
        </v-btn>
      </template>
    </InventoryTable>

    <v-dialog v-model="dialogRemoveConfirmationVisible" max-width="800">
      <v-card v-if="dialogRemoveConfirmationVisible === true">
        <v-card-title class="text-uppercase font-weight-regular py-2 panelheader">
          {{ tLabel("Remove selected pre-adviced unit?") }}
        </v-card-title>
        <v-card-text>
          <v-row align="center">
            <v-col cols="12">
              <v-simple-table>
                <template v-slot:default>
                  <thead class="header">
                    <tr>
                      <!-- <th class="text-left">id</th> -->
                      <th class="text-left">{{ tLabel("Unit") }}</th>
                      <th class="text-left">{{ tLabel("Owner") }}</th>
                      <th class="text-left">{{ tLabel("Unit Type") }}</th>
                      <th class="text-left">{{ tLabel("ISO Size Type") }}</th>
                      <th class="text-left">{{ tLabel("Items") }}</th>
                      <th class="text-left">{{ tLabel("WGT") }}</th>
                      <th class="text-left">{{ tLabel("Full/Empty") }}</th>
                      <th class="text-left">{{ tLabel("UNDG") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="red lighten-4">
                      <!-- <td>{{ gateInSubmitReqObj.unit.id }}</td> -->
                      <td>
                        <span class="font-weight-bold">{{ itemToRemove.objectCode }}</span>
                      </td>
                      <td>{{ itemToRemove.owner }}</td>
                      <td>{{ itemToRemove.unitTypeCode }}</td>
                      <td>{{ itemToRemove.isoSizeType }}</td>
                      <td>{{ itemToRemove.qty }}</td>
                      <td>{{ itemToRemove.grossweight }}</td>
                      <td>
                        <div>
                          <table>
                            <tr>
                              <td v-for="fe in fullEmpties" :key="fe.value">
                                <v-icon v-if="itemToRemove.fullEmpty === fe.value" color="primary" :title="fullEmptiesMap[fe.value]">{{
                                  "mdi-alpha-" + fe.value.toLowerCase() + "-box"
                                }}</v-icon>
                                <v-icon v-else :title="fullEmptiesMap[fe.value]">{{ "mdi-alpha-" + fe.value.toLowerCase() + "-box-outline" }}</v-icon>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </td>
                      <td>
                        <div>
                          <v-icon v-if="itemToRemove.dangerous === true" color="warning">mdi-alert</v-icon>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="doRemoveItem" color="success">{{ tLabel("Ok") }}<v-icon>mdi-delete</v-icon></v-btn>
          <v-btn @click="discardRemoveItem" color="error">{{ tLabel("Cancel") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogGateInVisible" fullscreen scrollable persistent>
      <GateIn :gateInSubmitReqObj="gateInSubmitReqObj" />
    </v-dialog>
  </div>
</template>

<script>
import InventoryTable from "@/components/yard/InventoryTable";
import GateIn from "@/components/yard/GateIn";
import YardEnumMixin from "../../../mixins/yard/EnumMixin";

export default {
  name: "PreadvicedInventoryVisits",
  data() {
    return {
      item: null,
      itemToRemove: null,
      dialogGateInVisible: false,
      dialogRemoveConfirmationVisible: false,
      unitTypes: [],
      roroTypes: [],
      sysTypes: [],
      sysTypesMap: {},
      gateInSubmitReqObj: null,
      carrierAlso: false,
      defaultFilters: { preadvisedOnly: true, unitsAndCarrierOnly: true },
      defaultSort: { field: "data_in", desc: true },
      headers: [
        { text: `${this.tLabel("Type")}`, value: "sysType" },
        { text: `${this.tLabel("Code")}`, value: "objectCode" },
        { text: `${this.tLabel("Operator")}`, value: "owner" },
        { text: `${this.tLabel("Unit Type")}`, value: "typeCode", sortable: false },
        { text: `${this.tLabel("ISO Size Type")}`, value: "isoSizeType" },
        { text: `${this.tLabel("Destination")}`, value: "destination" },
        { text: `${this.tLabel("Items")}`, value: "qty" },
        { text: `${this.tLabel("WGT")}`, value: "grossweight" },
        { text: `${this.tLabel("Customs Status")}`, value: "customsStatus" },
        { text: `${this.tLabel("Full/Empty")}`, value: "fullEmpty" },
        { text: `${this.tLabel("UNDG")}`, value: "dangerous" },
        { text: `${this.tLabel("Order")}`, value: "hinOrderCode" },
        { text: `${this.tLabel("Planned Date In")}`, value: "hinPlannedDate", formatValue: "formatDateTimeFromDate" },
        { text: `${this.tLabel("Out")}`, value: "houtTransportType" },
        { text: `${this.tLabel("Voyage Out")}`, value: "houtVoyageCode" },
        { text: `${this.tLabel("Planned Date Out")}`, value: "houtPlannedDate", formatValue: "formatDateTimeFromDate" },
        
      ],
    };
  },
  async mounted() {
    this.loadCombo();
  },
  async created() {
    this.$eventBus.$on("gateInDone", (gateInDone) => {
      console.log("gateInDone", gateInDone);
      this.dialogGateInVisible = false;
      if (gateInDone === true) {
        this.$emit("refreshTable");
      }
    });
  },
  methods: {
    goToCreatePreadvice() {
      this.$router.push({ path: "/yard/inventory/createPreadvice" });
      return;
    },

    async prepareGateIn(item) {
      console.log("prepareGateIn item ---------------> ", item);
      var req = {
        unit: {
          id: item.id,
          code: item.objectCode,
          owner: item.owner,
          unitTypeCode: item.unitTypeCode,
          isoSizeType: item.isoSizeType,
          qty: item.qty,
          netWeight: item.netWeight,
          grossweight: item.grossweight,
          fullEmpty: item.fullEmpty,
          dangerous: item.dangerous,
          positionCode: item.positionCodeYard,
          seal1: item.seal1,
          seal2: item.seal2,
          seal3: item.seal3,
          sysType: item.sysType,
          damages: [],
          itineraryVisit: {},
        },
        carrier: {},
        driver: {},
      };

      // get unit handling in
      const handlingIn = await this.$api.yardData.getHandlingByVisitIdAndDirection(item.id, "IN");
      const itineraryVisit = await this.$api.yardData.getItineraryByVisitId(item.id);
      if (itineraryVisit) {
        req.unit.itineraryVisit = itineraryVisit;
      }
      if (handlingIn) {
        req.handlingInRemarks = handlingIn.remarks;
        req.unit.orderCode = handlingIn.orderCode;
        if (handlingIn.carrierVisitId) {
          var carrierVisit = await this.$api.yardData.getRoroVisitById(handlingIn.carrierVisitId);
          if (carrierVisit) {
            req.carrier.id = carrierVisit.id;
            req.carrier.licensePlate = carrierVisit.objectCode;
            req.carrier.ownerCompanyId = carrierVisit.ownerCompanyId;

            var personsVisits = await this.$api.yardData.getPersonVisitsByPrimaryId(handlingIn.carrierVisitId);
            console.log("personVisit", personsVisits);
            if (personsVisits && personsVisits.length > 0) {
              req.driver.id = personsVisits[0].id;
              req.driver.name = personsVisits[0].name;
              req.driver.surname = personsVisits[0].surname;
              req.driver.companyId = personsVisits[0].ownerCompanyId;
              req.driver.identityDocNumber = personsVisits[0].identityDocNumber;
              req.driver.driverLicense = personsVisits[0].driverLicense;
              req.driver.badge = personsVisits[0].badge;
            }
          }
        }
      }
      this.gateInSubmitReqObj = JSON.parse(JSON.stringify(req));
      console.log("prepareGateIn gateInSubmitReqObj ---------------> ", this.gateInSubmitReqObj);
      this.dialogGateInVisible = true;
    },
    async loadCombo() {
      this.unitTypes = await this.$api.get(this.$apiConfiguration.BASE_PATH_YARD + "unit-types/comboList");

      let goodsType = await this.$api.get(this.$apiConfiguration.BASE_PATH_YARD + "goods-types/comboList");
      this.unitTypes.push.apply(this.unitTypes, goodsType);

      this.roroType = await this.$api.get(this.$apiConfiguration.BASE_PATH_YARD + "rorotypes/comboList");
      this.unitTypes.push.apply(this.unitTypes, this.roroType);

      const all = await this.$api.yardData.getLookups();
      this.sysTypes = this.mapList(this.sysTypesMap, all.sysTypes);
    },
    prepareRemoveItem(item) {
      this.itemToRemove = item;
      console.log("removeItem itemToRemove", this.itemToRemove);
      this.dialogRemoveConfirmationVisible = true;
    },

    async doRemoveItem() {
      console.log("doRemoveItem itemToRemove", this.itemToRemove.id, this.itemToRemove);
      await this.$api.yardData.removePreadviceItem(this.itemToRemove.id);
      this.itemToRemove = null;
      this.dialogRemoveConfirmationVisible = false;
      this.$emit("refreshTable");
    },

    discardRemoveItem() {
      this.itemToRemove = null;
      console.log("discardRemoveItem itemToRemove nullified", this.itemToRemove);
      this.dialogRemoveConfirmationVisible = false;
    },
    initFilters(filters) {
      this.carrierAlso = false;
      filters.unitsOnly = true;
      delete filters["unitsAndCarrierOnly"];
    },
    toggleCarrierAlso(filters) {
      if (this.carrierAlso) {
        filters.unitsAndCarrierOnly = true;     
        delete filters["unitsOnly"];
      } else {
        filters.unitsOnly = true;
        delete filters["unitsAndCarrierOnly"];
      }
      this.$emit("refreshTable");
    },
  },
  components: { InventoryTable, GateIn },
  mixins: [YardEnumMixin],
};
</script>
